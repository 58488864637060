export enum FeaturesRoutingEnum {
  Home = '',
  Themes = 'themes',
  Questions = 'questions',
  Transformers = 'transformers',
  Product = 'products',
  Suggestions = 'suggestions',
  Organizations = 'organizations',
  NotFound = 'not-found',
}
