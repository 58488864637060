import { LangEnum } from '@wizbii/models';

export const LanguageByLocale: Record<LangEnum, string> = {
  [LangEnum.fr]: 'français',
  [LangEnum.en]: 'anglais',
  [LangEnum.it]: 'italien',
  [LangEnum.de]: 'allemand',
  [LangEnum.es]: 'espagnol',
};

export type ResourceType = 'question' | 'transformer' | 'theme' | 'product' | 'suggestion' | 'organization';

export enum ResourceTypeEnum {
  Question = 'question',
  Transformer = 'transformer',
  Theme = 'theme',
  Product = 'product',
  Suggestion = 'suggestion',
}

export const LabelByType: Record<ResourceType, string> = {
  transformer: 'ce transformer',
  theme: 'ce thème',
  suggestion: 'cette suggestion',
  product: 'ce produit',
  question: 'cette question',
  organization: 'cet organisme',
};
