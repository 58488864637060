import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, NgModule, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import {
  NavigationAction,
  NavigationItem,
  WzbBoNavigationSidebarMenuComponent,
  wzbBoNavigationSidebarMenuIconsDirective,
} from '@wizbii-components/bo-angular-ui';

@Component({
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardLayoutComponent implements OnInit {
  activeRoute!: string | string[];
  env = environment.apiPlatform;
  get iconList(): string[] {
    return [...this.items].map((i) => i.icon);
  }

  items: any[] = [];
  allItems = [
    {
      id: 'themes',
      route: `/${FeaturesRoutingEnum.Themes}`,
      label: 'Thèmes',
      icon: 'internet',
      isImplemented: true,
    },
    {
      id: 'questions',
      route: `/${FeaturesRoutingEnum.Questions}`,
      label: 'Questions',
      icon: 'filled-document',
      isImplemented: true,
    },
    {
      id: 'transformers',
      route: `/${FeaturesRoutingEnum.Transformers}`,
      label: 'Transformers',
      icon: 'loading',
      isImplemented: true,
    },
    {
      id: 'suggestion',
      route: `/${FeaturesRoutingEnum.Suggestions}`,
      label: 'Suggestion',
      icon: 'lamp',
      isImplemented: true,
    },
    {
      id: 'Organization',
      route: `/${FeaturesRoutingEnum.Organizations}`,
      label: 'Organismes',
      icon: 'folder',
      isImplemented: true,
    },
    {
      id: 'produits',
      route: `/${FeaturesRoutingEnum.Product}`,
      label: 'Produits',
      icon: 'delivery',
      isImplemented: true,
    },
  ];

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.items = this.allItems.filter((it) => {
      return it.isImplemented || false;
    });

    this.activeRoute = `/${this.router.url.split('/')[1]}`;
  }

  handleItemClicked(item: NavigationItem | NavigationAction): void {
    this.router.navigate([(item as NavigationItem).route]);
  }

  trackByIcon(_: number, icon: string): string {
    return icon;
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    WzbBoNavigationSidebarMenuComponent,
    wzbBoNavigationSidebarMenuIconsDirective,
    MatSidenavModule,
    MatIconModule,
  ],
  declarations: [DashboardLayoutComponent],
  exports: [DashboardLayoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardLayoutModule {}
