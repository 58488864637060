import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { CommitOptions } from '@models/commons/utils';
import { Incompatibility } from '@models/incompatibility';
import { Product } from '@models/product';
import { Question } from '@models/question';
import { Suggestion } from '@models/suggestion';
import { Theme } from '@models/theme';
import { Transformer } from '@models/transformer';
import { Organization } from '@wizbii/models';
import { Observable } from 'rxjs';

const CommitMessageHeader = 'COMMIT-MESSAGE';

@Injectable()
export class SuggestionWebservice {
  private readonly baseUrl = environment.api.suggestion;
  private readonly themeUrl = `${this.baseUrl}/internal/themes`;
  private readonly organizationUrl = `${this.baseUrl}/internal/organizations`;
  private readonly questionUrl = `${this.baseUrl}/internal/questions`;
  private readonly dependenciesUrl = `${this.baseUrl}/internal/dependencies`;
  private readonly productUrl = `${this.baseUrl}/internal/suggestion_sets`;
  private readonly suggestionUrl = `${this.baseUrl}/internal/suggestions`;
  private readonly transformerUrl = `${this.baseUrl}/internal/transformers`;
  private readonly incompatibilitiesUrl = `${this.baseUrl}/internal/incompatibilities`;

  constructor(private readonly http: HttpClient) {}

  // THEMES
  getThemes(): Observable<Theme[]> {
    return this.http.get<Theme[]>(this.themeUrl);
  }

  getTheme(themeId: string): Observable<Theme> {
    return this.http.get<Theme>(`${this.themeUrl}/${themeId}`);
  }

  getThemeRevisions(themeId: string): Observable<Theme[]> {
    return this.http.get<Theme[]>(`${this.themeUrl}/${themeId}/revisions`);
  }

  getThemeRevision(revisionId: string): Observable<Theme> {
    return this.http.get<Theme>(`${this.themeUrl}/${revisionId}/revision`);
  }

  publishTheme(themeId: string): Observable<Theme> {
    return this.http.patch<Theme>(`${this.themeUrl}/${themeId}/revisions/publish`, {});
  }

  restoreThemeRevision(revisionId: string): Observable<Theme> {
    return this.http.patch<Theme>(`${this.themeUrl}/${revisionId}/revisions/restore`, { id: revisionId });
  }

  createTheme(theme: Partial<Theme>): Observable<Theme> {
    return this.http.post<Theme>(this.themeUrl, theme);
  }

  updateTheme(theme: Theme, options: CommitOptions): Observable<Theme> {
    const headers = new HttpHeaders({
      ...(options.message && { [CommitMessageHeader]: options.message }),
    });
    return this.http.put<Theme>(`${this.themeUrl}/${theme.themeId}`, theme, { headers });
  }

  deleteTheme(themeId: string): Observable<Theme> {
    return this.http.delete<Theme>(`${this.themeUrl}/${themeId}`);
  }

  // QUESTIONS
  getQuestions(): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.questionUrl}`);
  }

  getQuestion(questionId: string): Observable<Question> {
    return this.http.get<Question>(`${this.questionUrl}/${questionId}`);
  }

  getQuestionRevisions(questionId: string): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.questionUrl}/${questionId}/revisions?row=100`);
  }

  getQuestionRevision(revisionId: string): Observable<Question> {
    return this.http.get<Question>(`${this.questionUrl}/${revisionId}/revision`);
  }

  publishQuestion(questionId: string): Observable<Question> {
    return this.http.patch<Question>(`${this.questionUrl}/${questionId}/revisions/publish`, {});
  }

  restoreQuestionRevision(revisionId: string): Observable<Question> {
    return this.http.patch<Question>(`${this.questionUrl}/${revisionId}/revisions/restore`, { id: revisionId });
  }

  createQuestion(question: Partial<Question>): Observable<Question> {
    return this.http.post<Question>(this.questionUrl, question);
  }

  updateQuestion(question: Question, options: CommitOptions): Observable<Question> {
    const headers = new HttpHeaders({
      ...(options.message && { [CommitMessageHeader]: options.message }),
    });
    return this.http.put<Question>(`${this.questionUrl}/${question.questionId}`, question, { headers });
  }

  deleteQuestion(questionId: string): Observable<Question> {
    return this.http.delete<Question>(`${this.questionUrl}/${questionId}`);
  }

  getAssociatedQuestions(questionId: string): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.questionUrl}/${questionId}/associated-questions`);
  }

  getQuestionDependencies(questionId: string): Observable<Suggestion[]> {
    return this.http.get<Suggestion[]>(`${this.dependenciesUrl}/questions/${questionId}`);
  }

  // PRODUCTS
  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(this.productUrl);
  }

  getProduct(suggestionSetId: string): Observable<Product> {
    return this.http.get<Product>(`${this.productUrl}/${suggestionSetId}`);
  }

  createProduct(product: Partial<Product>): Observable<Product> {
    return this.http.post<Product>(this.productUrl, product);
  }

  updateProduct(product: Product, options: CommitOptions): Observable<Product> {
    const headers = new HttpHeaders({
      ...(options.message && { [CommitMessageHeader]: options.message }),
    });
    return this.http.put<Product>(`${this.productUrl}/${product.suggestionSetId}`, product, { headers });
  }

  getProductRevisions(suggestionSetId: string): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.productUrl}/${suggestionSetId}/revisions?row=100`);
  }

  getProductRevision(revisionId: string): Observable<Product> {
    return this.http.get<Product>(`${this.productUrl}/${revisionId}/revision`);
  }

  publishProduct(suggestionSetId: string): Observable<Product> {
    return this.http.patch<Product>(`${this.productUrl}/${suggestionSetId}/revisions/publish`, {});
  }

  restoreProductRevision(revisionId: string): Observable<Product> {
    return this.http.patch<Product>(`${this.productUrl}/${revisionId}/revisions/restore`, { id: revisionId });
  }

  deleteProduct(suggestionSetId: string): Observable<Product> {
    return this.http.delete<Product>(`${this.productUrl}/${suggestionSetId}`);
  }

  // SUGGESTIONS
  createSuggestion(suggestion: Partial<Suggestion>): Observable<Suggestion> {
    return this.http.post<Suggestion>(this.suggestionUrl, suggestion);
  }

  updateSuggestion(suggestion: Suggestion, options: CommitOptions): Observable<Suggestion> {
    const headers = new HttpHeaders({
      ...(options.message && { [CommitMessageHeader]: options.message }),
    });

    return this.http.put<Suggestion>(`${this.suggestionUrl}/${suggestion.suggestionId}`, suggestion, { headers });
  }

  getSuggestion(suggestionId: string): Observable<Suggestion> {
    return this.http.get<Suggestion>(`${this.suggestionUrl}/${suggestionId}`);
  }

  getSuggestionRevisions(suggestionId: string): Observable<Suggestion[]> {
    return this.http.get<Suggestion[]>(`${this.suggestionUrl}/${suggestionId}/revisions?row=100`);
  }

  getSuggestionRevision(revisionId: string): Observable<Suggestion> {
    return this.http.get<Suggestion>(`${this.suggestionUrl}/${revisionId}/revision`);
  }

  publishSuggestion(suggestionId: string): Observable<Suggestion> {
    return this.http.patch<Suggestion>(`${this.suggestionUrl}/${suggestionId}/revisions/publish`, {});
  }

  restoreSuggestionRevision(revisionId: string): Observable<Suggestion> {
    return this.http.patch<Suggestion>(`${this.suggestionUrl}/${revisionId}/revisions/restore`, { id: revisionId });
  }

  getSuggestions(page: number, perPage: number): Observable<Suggestion[]> {
    return this.http.get<Suggestion[]>(this.suggestionUrl, { params: { page: page || 1, perPage: perPage || 20 } });
  }

  deleteSuggestion(suggestionId: string): Observable<Suggestion> {
    return this.http.delete<Suggestion>(`${this.suggestionUrl}/${suggestionId}`);
  }

  // organizations
  getOrganizations(): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.organizationUrl);
  }

  getOrganization(organizationId: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.organizationUrl}/${organizationId}`);
  }

  createOrganization(organization: Partial<Organization>): Observable<Organization> {
    return this.http.post<Organization>(this.organizationUrl, organization);
  }

  updateOrganization(organization: Organization, options: CommitOptions): Observable<Organization> {
    const headers = new HttpHeaders({
      ...(options.message && { [CommitMessageHeader]: options.message }),
    });
    return this.http.patch<Organization>(
      `${this.organizationUrl}/${organization.id}`,
      { name: organization.name, description: organization.description, id: organization.id },
      {
        headers,
      }
    );
  }

  // TRANSFORMERS
  getTransformers(target?: string | null): Observable<Transformer[]> {
    return this.http.get<Transformer[]>(this.transformerUrl, target ? { params: { target } } : {});
  }

  getTransformer(id: string): Observable<Transformer> {
    return this.http.get<Transformer>(`${this.transformerUrl}/${id}`);
  }

  getTransformerRevisions(id: string): Observable<Transformer[]> {
    return this.http.get<Transformer[]>(`${this.transformerUrl}/${id}/revisions`);
  }

  getTransformerRevision(revisionId: string): Observable<Transformer> {
    return this.http.get<Transformer>(`${this.transformerUrl}/${revisionId}/revision`);
  }

  publishTransformer(transformerId: string): Observable<Transformer> {
    return this.http.patch<Transformer>(`${this.transformerUrl}/${transformerId}/revisions/publish`, {});
  }

  restoreTransformerRevision(revisionId: string): Observable<Transformer> {
    return this.http.patch<Transformer>(`${this.transformerUrl}/${revisionId}/revisions/restore`, { id: revisionId });
  }

  createTransformer(transformer: Partial<Transformer>): Observable<Transformer> {
    return this.http.post<Transformer>(this.transformerUrl, transformer);
  }

  updateTransformer(transformer: Transformer, options: CommitOptions): Observable<Transformer> {
    const headers = new HttpHeaders({
      ...(options.message && { [CommitMessageHeader]: options.message }),
    });
    return this.http.put<Transformer>(`${this.transformerUrl}/${transformer.transformerId}`, transformer, { headers });
  }

  deleteTransformer(transformerId: string): Observable<Transformer> {
    return this.http.delete<Transformer>(`${this.transformerUrl}/${transformerId}`);
  }

  verifyExpression(expression: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/internal/validation/expression`, expression);
  }

  // INCOMPATIBILITIES
  getIncompatibilitiesFromSuggestion(suggestionId: string): Observable<Incompatibility[]> {
    return this.http.get<Incompatibility[]>(`${this.incompatibilitiesUrl}/${suggestionId}/incompatibilities`);
  }

  createIncompatibility(incompatibility: Partial<Incompatibility>): Observable<Incompatibility> {
    return this.http.post<Incompatibility>(this.incompatibilitiesUrl, incompatibility);
  }

  createIncompatibilities(incompatibilities: Partial<Incompatibility>[]): Observable<Incompatibility[]> {
    return this.http.post<Incompatibility[]>(`${this.incompatibilitiesUrl}/create`, { incompatibilities });
  }

  deleteIncompatibilities(incompatibilityIds: string[]): Observable<void> {
    return this.http.delete<void>(`${this.incompatibilitiesUrl}/delete/${incompatibilityIds.join(',')}`);
  }

  deleteIncompatibility(incompatibilityId: string): Observable<void> {
    return this.http.delete<void>(`${this.incompatibilitiesUrl}/${incompatibilityId}`);
  }
}
